.schedule {
  padding-bottom: 20px;
  position: relative;
}
.focus {
  background: #fff;
  width: 90%;
  max-width: 90%;
  height: 470px;
  max-height: 470px;
  box-shadow: 0 4px 8px 0 rgb(167, 167, 167, 0.4);
  margin: 30px auto;
  padding: 10px 5px;
}
nav {
  text-align: left;
}
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  color: #acacd7;
}
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  color: #1a1a54;
  border-bottom: 3px solid #ff006b;
  /* border-radius: 5%; */
  display: inline-block;
  /* margin: 20px; */
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link.active:hover,
.navbar-light .navbar-nav .nav-link.show:hover,
.navbar-light .navbar-nav .show > .nav-link:hover {
  /* color: #F88600; */
  /* border-bottom: 3px solid #ff006b; */
  color: #1a1a54;
}
button {
  border: 0;
  background: none;
  font-size: small;
  text-align: left;
}
.details {
  font-size: small;
  padding: 5px 0px;
}
h5 {
  font-size: medium;
}
/* p {
  font-size: 0.7rem;
} */
.time {
  color: #1a1a54;
  font-size: 0.7rem;
}
.focus-speaker {
  color: #1a1a54;
  font-size: 0.7rem;
  font-weight: 500;
}
.focus-topic {
  color: #1a1a54;
}
.focus-text {
  color: #ff0069;
  font-size: 0.8rem;
}
.vector-one {
  display: none;
}
.vector-two {
  display: none;
}
.vector-three {
  display: none;
}
.vector-four {
  display: none;
}
@media only screen and (min-width: 350px) {
  .focus {
    background: #fff;
    width: 90%;
    max-width: 90%;
    height: 420px;
    max-height: 420px;
    box-shadow: 0 4px 8px 0 rgb(167, 167, 167, 0.4);
    margin: 10px auto;
  }
}
@media only screen and (min-width: 426px) {
  .focus {
    background: #fff;
    width: 70%;
    max-width: 70%;
    height: 430px;
    max-height: 430px;
    box-shadow: 0 4px 8px 0 rgb(167, 167, 167, 0.4);
    margin: 10px auto;
  }
  nav {
    margin: 0 0 0 0;
  }
  button {
    border: 0;
    background: none;
    margin: 0 5px;
    font-size: small;
    text-align: left;
  }
  .details {
    font-size: small;
    padding: 5px 10px;
  }
  .vector-one {
    display: none;
  }
  .vector-two {
    display: none;
  }
  .vector-three {
    display: none;
  }
  .vector-four {
    display: none;
  }
}
@media only screen and (min-width: 615px) {
  .focus {
    background: #fff;
    width: 60%;
    max-width: 60%;
    padding: 30px 15px 30px 15px;
    height: 410px;
    max-height: 410px;
    box-shadow: 0 4px 8px 0 rgba(204, 204, 204, 0.4);
    margin: 10px auto;
  }
}
@media only screen and (min-width: 769px) {
  .focus {
    background: #fff;
    width: 60%;
    max-width: 60%;
    padding: 30px 15px 30px 15px;
    height: 400px;
    max-height: 410px;
    box-shadow: 0 4px 8px 0 rgba(204, 204, 204, 0.4);
    margin: 10px auto;
  }
  nav {
    margin: 0 0 0 0;
  }
  button {
    border: 0;
    background: none;
    margin: 0 5px;
    font-size: small;
    text-align: left;
  }
  .details {
    font-size: small;
    padding: 5px 25px;
  }
  .schedule {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
  }
  .schedule-body {
    width: 80%;
    max-width: 80%;
    margin: 0 auto;
  }
  .vector-one {
    display: block;
    width: 200px;
    height: auto;
    position: absolute;
    margin-top: 30px;
    right: 0;
    top: 1;
  }
  .vector-two {
    display: block;
    width: 200px;
    height: auto;
    position: absolute;
    margin-top: -50px;
    left: 0;
    top: 10;
  }
  .vector-three {
    display: block;
    width: 200px;
    height: auto;
    position: absolute;
    margin-top: -200px;
    right: 0;
    top: 1;
  }
  .vector-four {
    display: block;
    width: 200px;
    height: auto;
    margin-top: -170px;
    /* margin-left: -30px; */
    position: absolute;
    left: 0;
    top: 10;
  }
  .details {
    font-size: small;
    padding: 10px 5px;
  }
}

@media only screen and (min-width: 1000px) {
  .focus {
    background: #fff;
    width: 60%;
    max-width: 60%;
    padding: 30px 15px 30px 15px;
    height: 400px;
    max-height: 410px;
    box-shadow: 0 4px 8px 0 rgba(204, 204, 204, 0.4);
    margin: 10px auto;
  }
}
@media only screen and (min-width: 1920px) {
  .schedule {
    padding-bottom: 40px;
  }
  .details {
    font-size: small;
    padding: 5px 0px;
    margin: 10px auto;
  }
  .focus {
    background: #fff;
    width: 60%;
    max-width: 55%;
    padding: 10px;
    height: 550px;
    max-height: 550px;
    box-shadow: 0 8px 12px 0 rgba(206, 206, 206, 0.4);
    margin: 10px auto;
    margin-bottom: 90px;
  }
  nav {
    margin: 0 0 0 0;
  }
  button {
    border: 0;
    background: none;
    margin: 20px 5px 35px 20px;
    font-size: x-large;
    text-align: left;
  }
  .details {
    font-size: x-large;
    padding: 5px 25px;
  }
  .schedule {
    width: 80%;
    max-width: 80%;
    margin: 0 auto;
  }
  .schedule {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
  }
  .schedule-body {
    width: 80%;
    max-width: 80%;
    margin: 0 auto;
  }
  .vector-one {
    display: block;
    width: 300px;
    height: auto;
    position: absolute;
    margin-top: 30px;
    right: 0;
    top: 1;
  }
  .vector-two {
    display: block;
    width: 300px;
    height: auto;
    position: absolute;
    margin-top: -100px;
    left: 0;
    top: 10;
  }
  .vector-three {
    display: block;
    width: 300px;
    height: auto;
    position: absolute;
    margin-top: -230px;
    right: 0;
    top: 1;
  }
  .vector-four {
    display: block;
    width: 300px;
    height: auto;
    margin-top: -200px;
    /* margin-left: -30px; */
    position: absolute;
    left: 0;
    top: 10;
  }
  h5 {
    font-size: medium;
  }
  /* p {
    font-size: 0.7rem;
  } */
  .time {
    color: #1a1a54;
    font-size: 1.1rem;
  }
  .focus-speaker {
    color: #1a1a54;
    font-size: 1.1rem;
    font-weight: 500;
  }
  .focus-topic {
    color: #1a1a54;
    font-size: 1.6rem;
  }
  .focus-text {
    color: #ff0069;
    font-size: 1.1rem;
  }
}
