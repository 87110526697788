footer {
  background: #fefbeb;
  /* height: 350px; */
  color: #1a1a54;
  padding: 30px;
  text-align: center;
}
.footer-text {
  margin: 5px;
}
.sides {
  margin: 15px auto;
}
.footer-btn {
  background: #ff006b;
  font-family: roboto;
  font-size: small;
  color: #fff;
  /* border-radius: 50px; */
  /* padding: 7px 14px;
  margin: 10px;  */
  margin: 0 0 0 5px;
  margin-top: 10px;
  width: 90%;
}
.footer-btn:hover {
  background: #1a1a54;
  font-family: roboto;
  color: #fff;
  /* border-radius: 50px; */
}

.subscribe-input {
  padding: 4px 8px;
  margin: 0 5px 5px 5px;
  background-color: #fff;
  border: 1px solid #1a1a54;
}
.subscribe-input:focus {
  outline-width: 0;
}
i {
  width: 10%;
  height: auto;
  display: inline-block;
  margin-left: 10px;
}
.icons {
  margin: 0 5 20px 5;
}

i {
  width: 15%;
  height: auto;
  font-size: 1.5rem;
}
a i {
  color: #1a1a54;
}
a i:hover {
  color: #ff006b !important;
}
.subscribe-input {
  color: #1a1a54;
}
.footer-texta {
  margin: 10px 0 30px 0;
}
.footer-textb {
  margin: 20px 0 15px 0;
}

@media only screen and (min-width: 425px) {
  footer {
    text-align: center;
  }

  .footer-btn {
    background: #ff006b;
    font-family: roboto;
    font-size: small;
    color: #fff;
    /* border-radius: 0; */
    padding: 11px 22px;
    /* margin: 10px; */
    margin: 10px 0 0 5px;
    margin-top: 10px;
    width: 90%;
  }
  i {
    width: 15%;
    height: auto;
  }
  .icons {
    margin-top: 20px;
  }
  .footer-texta {
    margin: 10px 0 20px 0;
  }
  .footer-textb {
    margin: 20px 0 15px 0;
  }
}
@media only screen and (min-width: 768px) {
  footer {
    text-align: center;
  }
  input {
    margin-top: 20px;
  }
  .subscribe-input {
    width: 100%;
    margin: 10px 0 10px 0;
  }
  .footer-text {
    margin: 20px 0 0 0;
  }
  .footer-texta {
    margin: 10px 0 30px 0;
  }
  .footer-textb {
    margin: 20px 0 20px 0;
  }
  .footer-btn {
    background: #ff006b;
    font-family: roboto;
    font-size: medium;
    color: #fff;
    /* border-radius: 50px; */
    margin: 0 0 0 -20px;
    margin-top: 10px;
    padding: 9px 15px;
    width: auto;
  }
  i {
    width: 15%;
    height: auto;
  }
  .rower {
    width: 100%;
    margin: auto;
  }
  .icons {
    margin-top: 20px;
  }
}
@media only screen and (min-width: 1440px) {
  footer {
    text-align: left;
  }
  .footer-container {
    padding: 50px 0;
  }
  .subscribe-input {
    color: #1a1a54;
    width: 100%;
    font-size: 1.4rem;
    margin-top: 4px;
  }
  .footer-texta {
    margin: 0 0 55px 0;
  }
  .footer-textb {
    margin: 20px 0 40px 0;
  }
  .footer-btn {
    background: #ff006b;
    font-family: roboto;
    font-size: large;
    color: #fff;
    /* border-radius: 50px; */
    margin: 4px 0 0 -10px;
    padding: 7px 16px;
  }
  /* .sides {
    margin: 0 auto;
  } */
}

@media only screen and (min-width: 1920px) {
  footer {
    text-align: left;
  }
  .button {
    text-align: left;
  }
  .footer-container {
    padding: 80px 0;
  }
  .footer-btn {
    background: #ff006b;
    font-family: roboto;
    font-size: large;
    color: #fff;
    /* border-radius: 50px; */
    margin: 1px 0 0 30px;
    padding: 12px 24px;
  }
  i {
    width: 25%;
    height: auto;
    font-size: 2.5rem;
  }
  .footer-text {
    margin: 40px 0 30px 0;
    font-size: 1.5rem;
  }
  .footer-texta {
    margin: 20px 0 45px 0;
  }
  /* .footer-textb {
    margin: 28px 0 45px 0;
  } */
  .subscribe-input {
    color: #1a1a54;
    width: 400px;
    font-size: 1.4rem;
    margin-top: 0px;
  }
}
