.main {
  margin: 0;
  padding: 0;
  max-width: 100%;
  width: 100%;
}
.full-cover {
  margin: 0;
  /*padding: 0; */
  /* width: 100%; */
  background: #fefbeb;
}
.sideone {
  visibility: hidden;
  height: 0px;
  max-height: 0;
}
.sidetwo {
  padding: 0;
  /* width: 100%;
  max-width: 100%; */
  height: 300px;
  max-height: 300px;
}
.sidetwo img {
  width: 100%;
  max-width: 100%;
  height: 300px;
  max-height: 300px;
}
.writeoff {
  background-color: #1a1a54;
  width: 80%;
  margin: 130px auto 0;
  color: #fff;
  padding: 15px 30px;
  /* text-align: center; */
}
.writeoff h2 {
  font-weight: 500;
  font-size: 1.1rem;
}
.writeoff h5 {
  font-weight: 100;
  font-size: 0.7rem;
}
.writeoff-header {
  text-align: center;
  margin: 0 auto 20px;
}
.writeoff-content {
  text-align: justify;
}
.writeoff p {
  font-size: 0.5rem;
  font-weight: lighter;
  margin: 5px 0;
}
/* .slick-slider {
  width: 100%;
  height: 700px;
} */
.slick-prev {
  z-index: 9;
  /* background-color: #1a1a54; */
  left: 0;
}
.slick-next {
  z-index: 9;
  /* background-color: #1a1a54; */
  right: 25px;
}
.fa-times-circle {
  color: #e12c2c;
}
.full-cover {
  margin: 0;
  background: #fefbeb;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.sidetwo {
  /* width: 100%; */
  /* max-width: 100%; */
  /* height: 100%; */
  /* max-height: 100%; */
  /* background-image: url("./images/speaker1.png"); */
  background-size: cover;
  padding: 40px;
  text-align: right;
  color: #fff;
}
.sidetwo-header {
  text-align: center;
  font-size: small;
}
.sidetwo div .sidetwo-header {
  background-color: rgba(255, 0, 107, 0.2);
  padding: 20px 10px;
  position: relative;
  /* text-align: center; */
  /* margin-left: 80%; */
  width: 38%;
  margin: 0 auto;
  visibility: hidden;
}
/* .writeoff {
  position: absolute;
  background-color: #1a1a54;
  width: 7%;
  min-width: 7%;
  margin: 1% 0 0 -3%;
  color: #fff;
  padding: 20px 20px;
  
} */
.slick-next:before,
.slick-prev:before {
  font-size: 40px;
  line-height: 1;
  opacity: 0.75;
  color: #1a1a54;
}
.disguise {
  height: 340px;
  visibility: visible;
}
.redirect {
  text-align: center;
  margin: 10px auto 40px;
}
a {
  color: #fff;
}
a:hover {
  color: #ff006b;
}
@media only screen and (max-width: 370px) {
  .disguise {
    height: 390px;
    visibility: visible;
  }
  .redirect {
    text-align: center;
    margin: 10px auto 40px;
  }
  .sidetwo div .sidetwo-header {
    background-color: rgba(255, 0, 107, 0.2);
    padding: 20px 10px;
    position: relative;
    /* text-align: center; */
    /* margin-left: 80%; */
    width: 38%;
    margin: 0 auto;
    visibility: hidden;
  }
}
@media only screen and (min-width: 425px) {
  .writeoff {
    background-color: #1a1a54;
    width: 80%;
    margin: 170px auto 0;
    color: #fff;
    padding: 15px 30px;
    /* text-align: center; */
  }
  .disguise {
    height: 310px;
  }
  .redirect {
    text-align: center;
    margin: 0 auto 40px;
  }
  .sidetwo div .sidetwo-header {
    background-color: rgba(255, 0, 107, 0.2);
    padding: 20px 10px;
    position: relative;
    /* text-align: center; */
    /* margin-left: 80%; */
    width: 38%;
    margin: 0 auto;
    visibility: hidden;
  }
}
@media only screen and (min-width: 768px) {
  body,
  .main {
    height: 100vh;
    max-width: 100%;
    width: 100%;
  }
  .sideone {
    visibility: visible;
    padding: 40px;
    position: relative;
  }
  .slick-prev {
    z-index: 9;
    /* background-color: #1a1a54; */
    left: 0;
  }
  .slick-next {
    z-index: 9;
    /* background-color: #1a1a54; */
    right: 25px;
  }
  .fa-times-circle {
    color: #e12c2c;
  }
  i.fa-times-circle {
    color: #e12c2c;
  }
  i.fa-times-circle:hover {
    color: #f30909 !important;
  }
  .full-cover {
    margin: 0;
    background: #fefbeb;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .sidetwo {
    /* width: 100%; */
    /* max-width: 100%; */
    /* height: 100%; */
    /* max-height: 100%; */
    /* background-image: url("./images/speaker1.png"); */
    background-size: cover;
    padding: 40px;
    text-align: right;
    color: #fff;
    position: relative;
    /* width: 100%;
      max-width: 100%; */
    height: 100vh;
    max-height: 100vh;
  }
  .sidetwo-header {
    text-align: center;
    float: right;
    font-size: larger;
  }
  .sidetwo div .sidetwo-header {
    background-color: rgba(255, 0, 107, 0.2);
    padding: 20px 10px;
    position: relative;
    /* text-align: center; */
    /* margin-left: 80%; */
    width: 25%;
    visibility: visible;
  }
  .writeoff {
    position: absolute;
    background-color: #1a1a54;
    width: 65%;
    min-width: 65%;
    /* height: 60vh; */
    height: auto;
    margin: 5% 0 0 -25%;
    color: #fff;
    padding: 20px 40px;
    /* text-align: l; */
  }
  .writeoff-header {
    text-align: left;
  }
  .slick-next:before,
  .slick-prev:before {
    font-size: 40px;
    line-height: 1;
    opacity: 0.75;
    color: #1a1a54;
  }
  .writeoff h2 {
    font-weight: 500;
    font-size: 1.5rem;
  }
  .writeoff h5 {
    font-weight: 100;
    font-size: 0.9rem;
  }
  .writeoff-header {
    text-align: left;
    margin: 0 auto 20px;
  }
  .writeoff-content {
    text-align: justify;
  }
  .writeoff p {
    font-size: 0.7rem;
    font-weight: lighter;
    margin: 5px 0;
  }
}
@media only screen and (min-width: 890px) {
  .writeoff {
    position: absolute;
    background-color: #1a1a54;
    width: 65%;
    min-width: 65%;
    /* height: 60vh; */
    height: auto;
    margin: 6% 0 0 -25%;
    color: #fff;
    padding: 20px 40px;
    /* text-align: l; */
  }
}
@media (min-width: 992px) {
  .col-lg-3 {
    flex: 0 0 auto;
    width: 24%;
  }
  .writeoff {
    position: absolute;
    background-color: #1a1a54;
    width: 55%;
    min-width: 55%;
    /* height: 60vh; */
    height: auto;
    margin: 6% 0 0 -25%;
    color: #fff;
    padding: 20px 40px;
    /* text-align: l; */
  }
}
@media only screen and (min-width: 1200px) {
  .writeoff {
    position: absolute;
    background-color: #1a1a54;
    width: 45%;
    min-width: 45%;
    /* height: 60vh; */
    height: auto;
    margin: 6% 0 0 -25%;
    color: #fff;
    padding: 20px 40px;
    /* text-align: l; */
  }
}
/*  */

@media only screen and (min-width: 1920px) {
  body,
  .main {
    height: 100vh;
    max-width: 100%;
    width: 100%;
  }
  .sideone {
    visibility: visible;
    padding: 40px;
    position: relative;
  }
  .slick-prev {
    z-index: 9;
    /* background-color: #1a1a54; */
    left: 0;
  }
  .slick-next {
    z-index: 9;
    /* background-color: #1a1a54; */
    right: 75px;
  }
  i.fa-times-circle {
    color: #e12c2c;
  }
  i.fa-times-circle:hover {
    color: #f30909 !important;
  }
  .full-cover {
    margin: 0;
    background: #fefbeb;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .sidetwo {
    /* width: 100%; */
    /* max-width: 100%; */
    /* height: 100%; */
    /* max-height: 100%; */
    /* background-image: url("./images/speaker1.png"); */
    background-size: cover;
    padding: 40px;
    text-align: right;
    color: #fff;
    /* width: 100%;
      max-width: 100%; */
    height: 100vh;
    max-height: 100vh;
  }
  .sidetwo-header {
    text-align: center;
    float: right;
  }
  .sidetwo div .sidetwo-header {
    background-color: rgba(255, 0, 107, 0.2);
    padding: 20px 10px;
    position: relative;
    font-size: 1.7rem;
    /* text-align: center; */
    /* margin-left: 80%; */
    width: 25%;
  }
  .writeoff {
    position: absolute;
    background-color: #1a1a54;
    width: 43%;
    min-width: 7%;
    margin: 10% 0 0 -20%;
    color: #fff;
    padding: 30px 60px;
    /* text-align: l; */
  }
  .writeoff-header {
    text-align: left;
  }
  .slick-next:before,
  .slick-prev:before {
    font-size: 80px;
    line-height: 1;
    opacity: 0.75;
    color: #1a1a54;
  }
  .writeoff h2 {
    font-weight: 500;
    font-size: 1.9rem;
  }
  .writeoff h5 {
    font-weight: 100;
    font-size: 1.3rem;
  }
  .writeoff-header {
    text-align: left;
    margin: 0 auto 20px;
  }
  .writeoff-content {
    text-align: justify;
  }
  .writeoff p {
    font-size: 1.1rem;
    font-weight: lighter;
    margin: 5px 0;
  }
}
