.speakers-section {
  background: #fefbeb;
  text-align: center;
  padding: 10px;
  padding-bottom: 60px;
}
.speakers {
  width: 90%;
  max-width: 90%;
  margin: 0 auto;
}
.speakers-header {
  width: 80%;
  max-width: 80%;
  margin: 45px auto 25px;
  color: #1a1a54;
  font-size: 1.5rem;
}
.speakers-text {
  color: #060606;
  margin-bottom: 35px;
  width: 80%;
  max-width: 80%;
  margin: 0 auto 35px;
}
.all-speakers {
  width: 90%;
  max-width: 90%;
  margin: 0 auto;
}
.card {
  width: 85%;
  max-width: 85%;
  margin: 20px auto;
  border: 0px;
  text-align: left;
}
.card:hover {
  box-shadow: 2px 4px 10px 2px rgba(158, 158, 158, 0.2);
  transition: 0.4s ease-in-out;
  transform: scale(1.2);
}
.card-title {
  font-size: 1rem;
  margin: 0;
  font-weight: 700;
  color: #1a1a54;
}
.card-text {
  font-size: 0.5rem;
  font-weight: 700;
  color: #ff006b;
}
.card image {
  width: 100%;
  height: 260px;
}
.bio-linkage {
  text-decoration: underline;
  color: #ff006b;
  margin: 20px 0 0;
}
.bio-linkage:hover {
  text-decoration: underline;
  color: #1a1a54;
}
.bio-linkage strong {
  font-size: 0.6rem;
}
@media only screen and (min-width: 426px) {
  .card-text {
    font-size: 0.6rem;
    font-weight: 700;
    color: #ff006b;
  }
  .bio-linkage strong {
    font-size: 0.8rem;
  }
  .speakers-text {
    color: #060606;
    margin-bottom: 35px;
    width: 75%;
    max-width: 75%;
    margin: 0 auto 35px;
  }
}
@media only screen and (min-width: 768px) {
  .bio-linkage strong {
    font-size: 1rem;
  }
  .card-text {
    font-size: 0.6rem;
    font-weight: 700;
    color: #ff006b;
  }
  .card {
    width: 85%;
    max-width: 85%;
    height: 300px;
    max-height: 280px;
    margin: 35px auto;
    border: 0px;
    text-align: left;
  }
}
@media only screen and (min-width: 1920px) {
  .card {
    width: 85%;
    max-width: 85%;
    height: 350px;
    max-height: 350px;
    margin: 35px auto;
    border: 0px;
    text-align: left;
  }
  .card-title {
    font-size: 1.5rem;
    margin: 0;
    font-weight: 700;
    color: #1a1a54;
  }
  .card-text {
    font-size: 0.9rem;
    font-weight: 700;
    color: #ff006b;
  }
  .card image {
    width: 100%;
    height: 260px;
  }
  .speakers-header {
    margin-top: 45px;
    color: #1a1a54;
    font-size: 2.5rem;
  }
}
