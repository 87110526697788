#register {
  background: #1a1a54;
  color: #fff;
  padding-bottom: 20px;
}

.register-details {
  width: 90%;
  max-width: 90%;
  margin: 25px auto;

  /* text-align: center; */
}
.register-header {
  text-align: center;
  padding: 60px 0 10px 0;
  margin-top: 25px;
  font-size: 1.5rem;
  color: #fff;
}
form {
  width: 75%;
  max-width: 75%;
  margin: 0 auto;
}
p {
  margin-bottom: 0;
}
.input-field {
  margin: 10px 0 10px;
}
label {
  color: #fff;
  margin: 1px 0;
  font-size: 0.8rem;
}
input,
textarea {
  width: 100%;
  border: 1px solid #fff;
  border-color: #fff;
  border-radius: 5px;
  padding: 7px 15px;
  background-color: #1a1a54;
  color: #fff;
}
input,
textarea:focus {
  outline-width: 0;
}
textarea {
  height: 150px;
  border-radius: 15px;
}
.text-area {
  /* width: 100%; */
  margin: 10px auto;
}
.submit-button {
  text-align: center;
}

.header-btn:focus {
  outline: none;
  text-decoration: none;
}
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  font-size: 0.7rem;
}
/* .register-header {
  font-size: 2rem;
} */
.register-btn {
  margin: 5px 0 0 0;
  width: 100%;
}
@media only screen and (min-width: 426px) {
  input,
  textarea {
    width: 90%;
    border: 1px solid #fff;
    border-radius: 10px;
    padding: 10px;
    color: #fff;
  }
  input {
    height: 45px;
  }
  textarea {
    width: 90%;
    height: 120px;
    margin-top: 20px;
  }
  .register-text {
    margin: 0 auto;
  }
  .register-btn {
    margin: 5px 0 0 0;
    width: 100%;
  }
  .submit-button {
    text-align: right;
    padding: 0 10% 0 0;
  }
  .register-header {
    font-size: 1.8rem;
  }
}
@media only screen and (min-width: 728px) {
  textarea {
    width: 90%;
  }
  .register-btn {
    margin: 5px 0 0 0;
    width: auto;
  }
}
@media only screen and (min-width: 990px) {
  textarea {
    width: 95%;
  }
  .submit-button {
    text-align: right;
    padding: 0 5% 0 0;
  }
  .register-btn {
    margin: 5px 0 0 0;
    width: auto;
  }
}
@media only screen and (min-width: 1920px) {
  input,
  textarea {
    width: 100%;
    border: 1px solid #fff;
    border-radius: 10px;
    padding: 10px;
    font-size: 1.1rem;
    color: #fff;
    margin: 10px auto 10px;
  }
  textarea {
    width: 100%;
  }
  input {
    height: 55px;
  }
  textarea {
    height: 120px;
    margin-top: 20px;
  }
  input::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder {
    font-size: 1rem;
  }
  label {
    color: #fff;
    margin: 2px 0;
    font-size: 1.3rem;
  }
  .register-header {
    font-size: 2.1rem;
  }
  .register-text {
    margin: 10px auto 15px;
  }
  .submit-button {
    padding: 0;
  }
}
